@import "../../utilities/variables";
.tpk-toast {
    &.inactive {
        -webkit-animation: slide-out-top 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
        animation: slide-out-top 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
    }
    &.active {
        -webkit-animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    }
    position: fixed;
    border-radius: 0.25rem;
    border-left: 0.375rem solid;
    z-index: 1000;
    visibility: hidden;
    opacity: 0;
    left: 0;
    right: 0;
    top: 4.5rem;
    max-width: 80rem;
    width: calc(100% - 2rem);
    margin: 0 auto;
    @media #{$breakpoint-lg-up} {
        max-width: 90rem;
        width: 100%;
    }
    @media #{$breakpoint-md-up} {
        top: 9rem;
    }
    &--success {
        background-color: var(--green-light);
        border-color: var(--green);
    }
    &--danger {
        background-color: var(--red-light);
        border-color: var(--red);
    }
    &--alert {
        background-color: var(--yellow-light);
        border-color: var(--yellow);
    }
    &--lg {
        padding: 1.25rem 3.5rem 1.25rem 1rem;
        p {
            font-size: 0.875rem;
            line-height: 1.125rem;
        }
    }
    &--sm {
        padding: 0.5rem 3.5rem 1.125rem 0.75rem;
        p {
            font-size: 0.75rem;
            line-height: 0.875rem;
        }
    }
    &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        @media #{$breakpoint-md-up} {
            align-items: center;
            flex-direction: row;
        }
        p {
            @media #{$breakpoint-md-up} {
                margin-bottom: 0;
            }
        }
        .tpk-button--sm {
            @media #{$breakpoint-md-up} {
                margin: 0 1rem;
            }
        }
    }
    &__close {
        position: absolute;
        right: 1rem;
        top: 1rem;
        border: none;
        background-color: transparent;
        width: 1.5rem;
        height: 1.5rem;
        text-align: center;
        padding: 0;
    }
}

@-webkit-keyframes slide-in-top {
    0% {
        -webkit-transform: translateY(-1000px);
        transform: translateY(-1000px);
        opacity: 0;
        visibility: visible;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
    }
}
@keyframes slide-in-top {
    0% {
        -webkit-transform: translateY(-1000px);
        transform: translateY(-1000px);
        opacity: 0;
        visibility: hidden;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
    }
}
@-webkit-keyframes slide-out-top {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
    }
    100% {
        -webkit-transform: translateY(-1000px);
        transform: translateY(-1000px);
        opacity: 0;
        visibility: hidden;
    }
}
@keyframes slide-out-top {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
    }
    100% {
        -webkit-transform: translateY(-1000px);
        transform: translateY(-1000px);
        opacity: 0;
        visibility: hidden;
    }
}
